<template>
  <nav
    :class="{
      'opacity-0': !showNavbar,
      'opacity-100': showNavbar,
      'transition-opacity duration-300 ease-in-out': true,
    }"
    class="relative w-full text-white z-50"
  >
    <div
      class="flex absolute top-0 bg-black bg-opacity-80 flex-wrap w-full items-center justify-between py-2 gap-6 md:py-4 md:gap-0 px-6 md:px-12"
    >
      <input
        aria-hidden="true"
        type="checkbox"
        name="toggle_nav"
        id="toggle_nav"
        class="hidden peer"
      />
      <div
        class="relative z-20 w-full flex items-center justify-between lg:w-max md:px-0"
      >
        <a href="/" aria-label="logo" class="flex space-x-2 items-center">
          <img
            src="../assets/icon/logo-light.png"
            alt="logo-imoto"
            class="h-10"
          />
        </a>
        <div class="relative flex items-center lg:hidden max-h-10">
          <label
            role="button"
            for="toggle_nav"
            aria-label="humburger"
            id="hamburger"
            class="relative p-6 -mr-6"
          >
            <div
              aria-hidden="true"
              id="line"
              class="m-auto h-0.5 w-5 rounded bg-white transition duration-300"
            ></div>
            <div
              aria-hidden="true"
              id="line2"
              class="m-auto mt-2 h-0.5 w-5 rounded bg-white transition duration-300"
            ></div>
          </label>
        </div>
      </div>
      <div
        aria-hidden="true"
        class="fixed z-10 inset-0 h-auto w-screen origin-bottom scale-y-0 transition duration-500 peer-checked:origin-top peer-checked:scale-y-100 lg:hidden 0"
      ></div>
      <div
        class="flex-col z-20 bg-black bg-opacity-80 backdrop-blur-2xlflex-wrap gap-6 p-8 shadow-2xl shadow-gray-600/10 justify-end w-full invisible opacity-0 absolute top-full left-0 transition-all duration-300 scale-95 origin-top lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-7/12 lg:visible lg:opacity-100 lg:border-none peer-checked:scale-100 peer-checked:opacity-100 peer-checked:visible lg:shadow-none"
      >
        <div class="text-gray-600 lg:w-auto w-full lg:pt-0">
          <ul
            class="tracking-wide text-right font-medium lg:text-md flex-col flex lg:flex-row gap-6 lg:gap-0"
          >
            <li>
              <router-link
                to="/"
                class="block md:px-4 transition hover:text-primary text-white"
              >
                <span>Home</span>
              </router-link>
            </li>

            <li class="relative" ref="dropdown">
              <a
                href="#"
                @click.prevent="toggleDropdown"
                class="block md:px-4 transition hover:text-primary text-white"
              >
                <span>Product</span>
              </a>
              <ul
                v-if="isOpen"
                class="absolute right-0 mt-2 bg-white shadow-lg rounded-md py-2 w-48 z-20 transition duration-300 ease-in-out"
              >
                <li>
                  <a
                    href="/product/OctaGT"
                    class="block px-4 py-2 text-gray-700 hover:bg-gray-100 transition duration-200"
                    >Octa</a
                  >
                </li>
                <li>
                  <a
                    class="block px-4 py-2 text-gray-400 hover:bg-gray-100 transition duration-200"
                    >Vision (Coming Soon)</a
                  >
                </li>
              </ul>
            </li>
            <li
              class="block md:px-4 transition hover:text-primary text-white cursor-pointer"
              onclick="window.open('https://imotoshare.com', '_blank')"
            >
              <span>ImotoShare</span>
            </li>
            <li>
              <router-link
                to="/about"
                class="block md:px-4 transition hover:text-primary text-white"
              >
                <span>About</span>
              </router-link>
            </li>

            <li>
              <router-link
                to="/article"
                class="block md:px-4 transition hover:text-primary text-white"
              >
                <span>Article</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showNavbar: true,
      lastScrollTop: 0,
      isOpen: false,
    };
  },
  mounted() {
    // Replace 'scrollableElement' with the selector for your scrollable element
    const scrollableElement = document.querySelector(".snap-y");
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", this.handleScroll);
    }

    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    const scrollableElement = document.querySelector(".snap-y");
    if (scrollableElement) {
      scrollableElement.removeEventListener("scroll", this.handleScroll);
    }

    // Remove the event listener
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleScroll(event) {
      const currentScrollTop = event.target.scrollTop;

      if (currentScrollTop > this.lastScrollTop) {
        // Scrolling down
        this.showNavbar = false;
      } else {
        // Scrolling up
        this.showNavbar = true;
      }

      this.lastScrollTop = currentScrollTop;
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
#toggle_nav:checked ~ div #hamburger #line {
  @apply rotate-45 translate-y-1.5;
}

#toggle_nav:checked ~ div #hamburger #line2 {
  @apply -rotate-45 -translate-y-1;
}
</style>
