<template>
  <div class="w-full h-full">
    <div class="h-full w-full flex flex-col md:flex-row">
      <!-- Left Section -->
      <div class="md:w-3/6 h-full">
        <img
          src="../assets/octa/testride.webp"
          alt="Descriptive Alt Text"
          class="w-full h-full object-cover"
        />
      </div>

      <!-- Right Section -->
      <div class="md:w-3/6 h-full p-4">
        <div>
          <h2 class="text-4xl font-bold mb-4">Book a Test Ride</h2>
          <p class="text-sm font-medium">
            Submit your details to find out about our booking benefits with
            limited-time offers exclusives*.
          </p>
          <p class="text-lg my-4 animate-slow-blink">
            Please bring your driver's license along for doing the test drive.
          </p>
          <p class="my-4 text-xs text-gray-500">*Terms & conditions apply</p>

          <form @submit.prevent="submitForm">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <input
                id="name"
                v-model="form.fullName"
                type="text"
                placeholder="First Name"
                class="p-2 border border-gray-300 rounded"
                required
              />
              <input
                id="email"
                v-model="form.email"
                type="email"
                placeholder="Email"
                class="p-2 border border-gray-300 rounded"
                required
              />
              <input
                id="phone"
                v-model="form.phoneNumber"
                type="tel"
                placeholder="Phone Number"
                class="p-2 border border-gray-300 rounded"
                required
                inputmode="numeric"
                pattern="[0-9]*"
                @input="validatePhoneNumber"
              />

              <input
                id="city"
                v-model="form.city"
                type="text"
                placeholder="City"
                class="p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div class="flex mb-4">
              <input
                v-model="termsAccepted"
                type="checkbox"
                id="terms"
                class="mr-2"
              />
              <label for="terms" class="text-sm">
                I agree that you and IMOTO Indonesia and/or their service
                providers may call me and/or send marketing information and/or
                information on events and promotions to me about your or their
                products and services through SMS, MMS, fax, mailers or email.
              </label>
            </div>

            <!-- reCAPTCHA widget -->
            <div id="recaptcha"></div>

            <button
              :disabled="!termsAccepted || isSubmitting"
              class="bg-blue-500 text-white mt-4 px-4 py-2 rounded disabled:opacity-50 w-full"
            >
              Book Now
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      form: {
        fullName: "",
        email: "",
        phoneNumber: "",
        city: "",
      },
      termsAccepted: false,
      recaptchaVerified: false,
      isSubmitting: false,
      siteKey: "6Ldz9SoqAAAAAN2qbzTQp-rqR8MPSBkludCFTWLD", // Ganti dengan site key reCAPTCHA v2 Anda
    };
  },
  mounted() {
    this.loadRecaptchaScript();
  },
  methods: {
    loadRecaptchaScript() {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.renderRecaptcha();
      };
      document.head.appendChild(script);
    },
    renderRecaptcha() {
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.render("recaptcha", {
          sitekey: this.siteKey,
          callback: this.onVerify,
          "expired-callback": this.onExpired,
        });
      }
    },
    onVerify(response) {
      this.recaptchaVerified = true;
      console.log("ReCAPTCHA Verified:", response);
    },
    onExpired() {
      this.recaptchaVerified = false;
      console.log("ReCAPTCHA expired, please re-verify.");
    },
    async submitForm() {
      if (!this.recaptchaVerified) {
        alert("Please complete the CAPTCHA before submitting the form.");
        return;
      }

      if (this.isSubmitting) return;

      this.isSubmitting = true;

      try {
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbyKTNn_9rUlC9Qj6G-_Uxh0UeJC4fvOVgf4NES32NpBL9_pXebfPMKFv2Cr3r_t5v0d/exec",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            mode: "no-cors",
            body: JSON.stringify(this.form),
          }
        );

        alert("Form berhasil dikirim!");
        this.resetForm();
      } catch (error) {
        console.error("Error:", error);
        alert("Terjadi kesalahan saat mengirim form.");
      } finally {
        this.isSubmitting = false;
      }
    },
    resetForm() {
      this.form.fullName = "";
      this.form.email = "";
      this.form.phoneNumber = "";
      this.form.city = "";
      this.termsAccepted = false;
      this.recaptchaVerified = false;
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.reset();
      }
    },
  },
});
</script>

<style scoped>
@import "../style.css";
</style>
