<template>
  <div
    class="fixed mx-4 z-10 bottom-4 left-0 right-0 bg-white bg-opacity-60 text-gray-800 p-4 mb-5 md:mb-0 lg:mb-0 rounded-lg shadow-lg md:bottom-4 md:right-4 md:left-auto"
  >
    <carbon-counter />
  </div>
</template>

<script>
import CarbonCounter from "./CarbonCounter.vue";

export default {
  components: { CarbonCounter },
  name: "FloatingText",
};
</script>
