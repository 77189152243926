<template>
  <div class="text-xs lg:text-sm font-normal text-center">
    <div>
      <p>
        Carbon Reduced
        <span class="text-xs lg:text-sm font-bold text-blue-600">
          {{
            new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
              Math.round(data.carbon)
            )
          }}
          Kg
        </span>
      </p>
    </div>
    <div>
      <p>
        KMs covered
        <span class="text-xs lg:text-sm font-bold text-green-600">
          {{
            new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
              Math.round(data.km)
            )
          }}
          Km
        </span>
      </p>
    </div>
  </div>
</template>

<script>
const carbonInc = 153.575177482686 / (24 * 60 * 60);
const kmInc = 1354.636830578512 / (24 * 60 * 60);

export default {
  data() {
    return {
      data: {
        km: 327882.113,
        carbon: 37165.19295081,
      },
    };
  },
  methods: {
    timer() {
      let randSec = 3;
      console.log(randSec);

      setTimeout(() => {
        this.data.carbon += randSec * carbonInc;
        this.data.km += randSec * kmInc;
        this.timer();
      }, randSec * 1000);
    },
    immediate: true,
  },
  created() {
    const secDiff = Math.floor(
      (new Date() - Date.parse("21 Jun 2024 23:00:00")) / 1000
    );
    this.data.carbon += carbonInc * secDiff;
    this.data.km += kmInc * secDiff;
    this.timer();
  },
};
</script>
